<template>
    <div>
        <v-navigation-drawer v-model="drawer" absolute temporary app>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        <v-btn text @click="goTo('/')" class="text-black">
                            <img src="../../assets/logo/logo-panel2.png" class="logo-mobile title">
                        </v-btn>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense>
                <v-list-item link>
                    <v-btn text @click="goTo('/panel')" class="text-black link">
                        Harmonys
                    </v-btn>
                </v-list-item>

                <v-list-item link>
                    <v-btn @click="goTo('/panel/statistics')" text class="text-black link">
                        {{ $t('navbar.statistics') }}
                    </v-btn>
                </v-list-item>

                <!--<v-list-item link class="mt-2" v-if="user">
                    <v-btn href="/panel" outlined color="black" class="text-black link ma-2 rounded-md py-4" small>
                        <v-icon left>
                            mdi-apps
                        </v-icon>
                        Harmonys
                    </v-btn>
                </v-list-item>-->

                <v-list-item link class="mt-2" v-if="user">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" color="red lighten-2" class="white--text link ma-2 rounded-md py-4" small @click="logout()">
                                <v-icon center color="white" size="20px" class="mr-1">
                                    mdi-power-plug-off-outline
                                </v-icon>
                                {{ $t('navbar.disconnect') }}
                            </v-btn>
                        </template>
                        <span>{{ $t('navbar.disconnect') }}</span>
                    </v-tooltip>
                </v-list-item>

                <v-list-item link class="mt-3">

                    <v-select v-model="locale" :items="locales" @change="changeLocale" :label="$t('navbar.language')"
                        class="ml-0 mr-3 col-12" outlined dense item-value="value" item-text="text" return-object>
                        <template v-slot:selection="{ item }">
                            <v-avatar size="24">
                                <img :src="item.flag" />
                            </v-avatar>
                            <span class="ml-2">{{ item.text }}</span>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-avatar size="24">
                                <img :src="item.flag" />
                            </v-avatar>
                            <span class="ml-2">{{ item.text }}</span>
                        </template>
                    </v-select>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar color="white">
            <v-container>
                <v-row align="center" justify="space-between">
                    <div>
                        <v-btn text class="text-black" @click="goTo('/')">
                            <img src="../../assets/logo/logo-panel.png" class="logo">
                        </v-btn>
                        <!--<v-btn text href="/" class="text-black link hidden-sm-and-down">
                            {{ $t('navbar.home') }}
                        </v-btn>-->
                        <v-btn text class="text-black link hidden-sm-and-down" @click="goTo('/panel')">
                            Harmonys
                        </v-btn>
                        <v-btn text class="text-black link hidden-sm-and-down" @click="goTo('/panel/statistics')">
                            {{ $t('navbar.statistics') }}
                        </v-btn>

                        <v-btn @click="impersonateAdmin()" small class="white--text link" color="#7343d9" v-if="admin">
                            <v-icon class="mr-2" small>mdi-tools</v-icon>
                            Admin
                        </v-btn>

                        <v-btn to="/panel/admin" small class="white--text link" color="#7343d9" v-else-if="user.role_id == 1">
                            <v-icon class="mr-2" small>mdi-tools</v-icon>
                            Admin
                        </v-btn>
                    </div>
                    <v-row align="center" justify="end">

                        <template v-if="user">
                            <v-menu open-on-hover :close-on-content-click="false" :nudge-width="200" offset-x>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        class="mx-2 btn-profile"
                                        fab
                                        dark
                                        outlined
                                        color="white"
                                    >
                                        <transition name="fadeInDown">
                                            <v-badge
                                                bordered
                                                bottom
                                                color="teal accent-3"
                                                dot
                                                offset-x="10"
                                                offset-y="10"
                                                v-if="user.logo_path"
                                            >
                                                <v-avatar size="40">
                                                    <v-img :src="user.logo_path"></v-img>
                                                </v-avatar>
                                            </v-badge>

                                            <v-badge
                                                bordered
                                                bottom
                                                color="teal accent-3"
                                                dot
                                                offset-x="10"
                                                offset-y="10"
                                                v-else
                                            >
                                                <v-avatar color="black" size="38">
                                                    <span class="white--text text-p">
                                                        {{ user.first_name.charAt(0) + user.last_name.charAt(0) }}
                                                    </span>
                                                </v-avatar>
                                            </v-badge>
                                        </transition>
                                    </v-btn>
                                </template>

                                <v-card>
                                    <v-list max-width="350" >
                                        <v-list-item>
                                            <v-list-item-content class="text-left">
                                                <v-list-item-title>{{user.first_name + " " + user.last_name}}</v-list-item-title>
                                                <v-list-item-subtitle class="mt-4">{{user.email}}</v-list-item-subtitle>
                                                <v-list-item-subtitle class="mt-2">{{user.phone_code + " "+ user.phone_number}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-avatar height="70" width="70">
                                                <v-img cover :src="user.logo_path ? user.logo_path : require('../../assets/illustrations/no-photo.jpg')"/>
                                              </v-list-item-avatar>
                                        </v-list-item>
                                    </v-list>

                                    <v-divider></v-divider>

                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="#8e84c0"
                                        text
                                        to="/panel/profile"
                                    >
                                       {{ $t('navbar.editProfile') }}
                                    </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </template>

                        <template v-if="user">
                            <transition name="fadeInDown">
                                <v-btn outlined color="black" class="text-black link ma-2 rounded-md py-4" small @click="goTo('/panel/statistics')"
                                    :loading="earnings_loading">
                                    <v-icon left>
                                        mdi-cash
                                    </v-icon>
                                    ${{ earnings ? earnings : '0.00' }}
                                </v-btn>
                            </transition>

                            <transition name="fadeInDown">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" color="red lighten-2" class="link ma-2 rounded-md hidden-sm-and-down py-4" small @click="logout()">
                                            <v-icon center color="white" size="20px">
                                                mdi-power-plug-off-outline
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('navbar.disconnect') }}</span>
                                </v-tooltip>
                            </transition>
                        </template>

                        <v-col style="max-width: 200px;" class="p-0 pl-0 m-0 hidden-sm-and-down">
                            <v-select v-model="locale" :items="locales" @change="changeLocale"
                                :label="$t('navbar.language')" class="ml-4 mr-3 mt-6 hidden-sm-and-down" outlined dense
                                item-value="value" item-text="text" return-object>
                                <template v-slot:selection="{ item }">
                                    <v-avatar size="24">
                                        <img :src="item.flag" />
                                    </v-avatar>
                                    <span class="ml-2">{{ item.text }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <v-avatar size="24">
                                        <img :src="item.flag" />
                                    </v-avatar>
                                    <span class="ml-2">{{ item.text }}</span>
                                </template>
                            </v-select>
                        </v-col>

                        <v-btn text icon @click.stop="drawer = !drawer" color="black" class="hidden-md-and-up">
                            <v-icon>mdi-menu</v-icon>
                        </v-btn>
                    </v-row>
                </v-row>
            </v-container>
        </v-app-bar>
    </div>
</template>

<script>
    import axios from '@/axios.js';

    export default {
        name: 'PanelNavBar',
        data () {
            return {
                drawer: null,
                locale: null,
                locales: [
                    { value: 'en', text: 'English', flag: require('@/assets/flags/usa.png') },
                    { value: 'es', text: 'Español', flag: require('@/assets/flags/spain.png') }
                ],
                show1: false,
                user: null,
                admin: null,
                earnings_loading: false,
                earnings: 0
            }
        },

        computed: {
        },

        created() {
            this.setLanguage()
            this.checkUser()
            this.getEarnings()
        },

        beforeDestroy() {
            this.$root.$off('user-updated', this.updateUser);
            window.removeEventListener('storage', this.syncUserWithLocalStorage);
        },
        mounted() {
            this.$root.$on('user-updated', this.updateUser);
            window.addEventListener('storage', this.syncUserWithLocalStorage);
        },
        methods: {
            updateUser(updatedUser) {
                this.user = updatedUser;
            },
            syncUserWithLocalStorage() {
                const user = localStorage.getItem("user");
                if (user) {
                    this.user = JSON.parse(user);
                }
            },

            handlePanel() {
                this.panel();
            },

            goTo(url){
                this.$router.push(url);
            },

            checkUser(){
                let user = localStorage.getItem("user");
                let admin = localStorage.getItem("impersonate_admin");
                if (user){
                    this.user = JSON.parse(user);
                    this.admin = JSON.parse(admin);
                }
            },

            async impersonateAdmin() {
                try {
                    const response = await axios.post('impersonate-admin', { admin: this.admin});

                    // Guardar el nuevo token y datos de usuario en el local storage
                    localStorage.removeItem("impersonate_admin");
                    localStorage.setItem("user", JSON.stringify(response.data.user));
                    localStorage.setItem("token", response.data.token);

                    this.$vs.notify({
                        title: 'Impersonación exitosa',
                        text: `Ahora estás logueado como ${response.data.user.first_name}`,
                        color: 'success',
                        icon: 'check_box',
                        position: 'bottom-center',
                        time: 5000,
                    });

                    this.$router.push('/panel/admin');
                } catch (error) {
                    this.$vs.notify({
                        title: 'Ha ocurrido un error',
                        text: 'No se pudo impersonar',
                        color: 'danger',
                        icon: 'warning',
                        position: 'bottom-center',
                    });
                    console.error(error);
                }
            },

            logout() {
                try {
                        axios.get('/logout');

                        localStorage.removeItem("user");
                        localStorage.removeItem("token");

                        this.$vs.notify({
                            title: this.$t('navbar.disconnection'),
                            text: this.$t('navbar.disconnection-desc'),
                            color: 'success',
                            icon: 'check_box',
                            position: 'bottom-center',
                            time: 5000,
                        });
                        // Redirigir a la página principal y recargar
                        //window.location.href = "/";
                        this.$router.push('/');
                } catch (error) {
                    console.error(error);
                }
            },

            changeLocale() {
                this.$i18n.locale = this.locale.value;
                localStorage.setItem('locale', this.locale.value);
                this.$root.$emit('languageChanged', this.locale.value);
                this.$vs.notify({
                    title: this.$t('navbar.language'),
                    text: this.$t('navbar.language-success'),
                    color: 'success',
                    icon: 'check_box',
                    position: 'bottom-center',
                    time: 5000,
                })
            },

            async getEarnings() {
                this.earnings_loading = true
                try {
                    const response = await axios.get("get-earnings");
                    if (response){
                        this.earnings = response.data.total_value
                        this.earnings_loading = false
                    }
                } catch (error) {
                    console.error("Error:", error);
                }
            },

            setLanguage(){
                const storedLocale = localStorage.getItem('locale');
                if (storedLocale) {
                    this.locale = (storedLocale == 'es') 
                        ? { value: 'es', text: 'Español', flag: require('@/assets/flags/spain.png') } 
                        : { value: 'en', text: 'English', flag: require('@/assets/flags/usa.png') };
                    this.$i18n.locale = storedLocale;
                } else {
                    const browserLocale = navigator.language.slice(0, 2);
                    if (browserLocale === 'en' || browserLocale === 'es') {
                        this.locale = (browserLocale == 'es') 
                            ? { value: 'es', text: 'Español', flag: require('@/assets/flags/spain.png') } 
                            : { value: 'en', text: 'English', flag: require('@/assets/flags/usa.png') };
                        this.$i18n.locale = browserLocale;
                        localStorage.setItem('locale', browserLocale);
                    } else {
                        this.locale = { value: 'es', text: 'Español', flag: require('@/assets/flags/spain.png') };
                        this.$i18n.locale = 'es';
                        localStorage.setItem('locale', 'es');
                    }
                }
            },
        }
    };
</script>

<style scoped>
    .title-modal {
        color: #454547;
    }

    .desc {
        color: #9390a1;
    }

    .logo {
        width: 24px;
    }

    .logo-mobile {
        margin-left: -24px;
        width: 172px;
    }

    .title {
        font-size: 25px !important;
        text-transform: none !important; /* Evita la transformación a mayúsculas */
    }

    .link {
        text-transform: none !important;
    }

    .container {
        margin-top: 18px;
        max-width: 1100px !important;
    }

    header {
        height: 86px !important;
    }

    .border {
        border-color: gray;
        border-style: solid;
        border-width: 1px;
    }

    .btn-profile{
        width: fit-content;
        height: fit-content;
    }

    .gradient {
        width: 185px;
        padding: 8px;
        border-radius: 9px;
        background: #8e84c0;
        background: -moz-linear-gradient(90deg, #8e84c0 0%, rgb(236, 196, 255) 100%);
        background: -webkit-linear-gradient(90deg, #8e84c0 0%, rgb(236, 196, 255) 100%);
        background: linear-gradient(90deg, #8e84c0 0%, rgb(236, 196, 255) 100%);
        filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#8e84c0", endColorstr="rgb(236, 196, 255)", GradientType=1);
    }

    @media (max-width: 959px) {
        .logo {
            margin-left: -8px;
        }
    }
</style>
